<template>
  <div
    class="attendance-checkbox items-center"
    @click="toggleDropdown"
    ref="checkboxRef"
  >
    <div
      v-if="student?.attended !== null && student?.attended"
      class="flex-grow-1"
    >
      <BigAttendancePresentIcon />
    </div>
    <div v-if="student?.attended !== null && !student?.attended">
      <BigAttendanceAbsentIcon />
    </div>

    <div
      v-show="dropdownVisible"
      class="dropdown"
      ref="dropdownRef"
      @click.stop
    >
      <div
        v-for="(item, index) in dropdownList"
        :key="index"
        class="flex gap-3 py-2 px-4 dropdown__box"
        :class="index == dropdownList.length - 1 && 'dropdown__box-border'"
        @click="selectItem(item.value)"
      >
        <div><component :is="item.icon" /></div>
        <div>{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import AttendanceAbsentIcon from "@/assets/icons/AttendanceAbsentIcon.vue";
import AttendancePresentIcon from "@/assets/icons/AttendancePresentIcon.vue";
import BigAttendancePresentIcon from "@/assets/icons/BigAttendancePresentIcon.vue";
import BigAttendanceAbsentIcon from "@/assets/icons/BigAttendanceAbsentIcon.vue";

export default {
  name: "AttendanceCheckbox",
  components: {
    BigAttendanceAbsentIcon,
    BigAttendancePresentIcon,
    AttendancePresentIcon,
    AttendanceAbsentIcon,
  },
  props: {
    student: {
      type: Object,
      default: () => ({
        id: null,
        attended: null,
      }),
    },
  },
  data() {
    return {
      dropdownValue: null,
      dropdownVisible: false,
      dropdownList: [
        { icon: AttendancePresentIcon, text: "Присутствовал(-а)", value: true },
        { icon: AttendanceAbsentIcon, text: "Отсутствовал(-а)", value: false },
        { icon: null, text: "Убрать отметку", value: null },
      ],
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
      if (this.dropdownVisible) {
        window.addEventListener("click", this.closeDropdownOnClickOutside);
      } else {
        window.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    closeDropdownOnClickOutside(event) {
      if (
        !this.$refs.dropdownRef.contains(event.target) &&
        !this.$refs.checkboxRef.contains(event.target)
      ) {
        this.dropdownVisible = false;
        window.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    selectItem(value) {
      this.$emit("update", this.student.id, value);
      this.dropdownVisible = false;
    },
  },
};
</script>

<style scoped>
.attendance-checkbox {
  position: relative; /* Position relative to contain the absolute positioned dropdown */
  width: 22px;
  height: 22px;
  border: 2px solid #d1d5db;
  border-radius: 100px;
  flex-shrink: 0;
  cursor: pointer;
  display: flex; /* Ensures content centers inside the checkbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.dropdown {
  position: absolute;
  width: 200px;
  top: calc(100% + 5px); /* Position below the checkbox */
  left: 0;
  /* Rest of your styling */
}

.attendance-checkbox {
  position: relative; /* Position relative to contain the absolute positioned dropdown */
  width: 22px;
  height: 22px;
  border: 2px solid #d1d5db;
  border-radius: 100px;
  flex-shrink: 0;
  cursor: pointer;
  display: flex; /* Ensures content centers inside the checkbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.dropdown {
  position: absolute;
  width: 200px;
  top: 50%;
  left: 100%;
  transform: translateY(-40%) translateX(5%);
  /* Add styling for the dropdown */
  background: white;
  border-radius: 6px;
  box-shadow: 4px 10px 15px -3px #0000001a;
  display: flex;
  flex-direction: column;
  z-index: 10;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #111827;
  border: 1px solid #f9fafb;
}

.attendance-checkbox:hover .dropdown {
  display: block; /* Show dropdown when hovering over the checkbox */
}
.dropdown__box {
  margin: 4px 0;
}
.dropdown__box:hover {
  background-color: #f3f4f6; /* Darker background color on hover */
}
.dropdown__box-border {
  border-top: 1px solid #f9fafb;
}
</style>
